#root {
    max-width: 780px;
    width: 100%;

    margin: 0 auto;
    background-color: #fff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox  */
input[type="number"] {
    -moz-appearance: textfield;
}
@keyframes slideInUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideOutDown {
    from {
        transform: translateY(0);
        opacity: 1;
    }
    to {
        transform: translateY(100%);
        opacity: 0;
    }
}

.slideInUp {
    animation: slideInUp 0.3s forwards;
}

.slideOutDown {
    animation: slideOutDown 0.5s forwards;
}

.displayFlexDirect {
    display: flex;
    flex-direction: column;
}
.header2 {
    display: flex;

    align-items: center;
    justify-content: center;
    padding: 16px 45px;
    font-weight: 700;
    color: white;
    background-color: #07090c;
    font-size: 20px;
}

.header {
    border-bottom: 1px solid #ddd;
    position: relative;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    font-weight: 700;
    position: sticky;
    top: 0;
    z-index: 15;
    background: white;
}
.header.active {
    border-bottom: 1px solid #85dacd;
    font-size: 17px;
    background: #00c0a3;
    color: white;
}
.header .BackBtn {
    position: absolute;
    left: 10px;
    top: 22px;
    cursor: pointer;
    width: 20px;
}

.header .closeBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}
.ContentWrap {
    padding: 20px;
}

.ContentWrap .content {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-bottom: 8px;
}
.ContentWrap .content .left {
    display: flex;
    align-items: center;

    width: 100px;
}
.ContentWrap .content .left img {
    margin-right: 8px;
}
.ContentWrap .content .right {
    width: 242px;
    line-height: 20px;
}
.ContentInfoWrap .ContentInfoTitle {
    padding: 20px;
    margin-top: 12px;
    font-size: 20px;
    font-weight: 700;
}
.ContentInfo {
    border-top: 1px solid #ddd;
    margin-bottom: 20px;
}

.ContentInfo .info {
    display: flex;
    border-bottom: 1px solid #ddd;
    align-items: stretch;
}

.ContentInfo .info .title {
    display: flex;
    align-items: center;
    font-size: 14px;
    background: var(--Grey_F1, #f1f1f1);
    width: 25%;
    padding: 8px;
    line-height: 20px;
}
.ContentInfo .info .infoContent {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 8px;
    line-height: 20px;
    width: 80%;
}
.ContentInfo .info .infoContent p {
    word-break: keep-all;
    line-height: 20px;
}
.ContentInfo .info .infoContent .imgWrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4px;
}

.ContentInfo .info .infoContent .imgWrap .imgBox {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ContentWrap .contentTitle h1 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 12px;
}
.ContentWrap .materialWrap {
    padding: 20px 0;
    display: flex;
    width: 100%;
    border-bottom: 1px solid #ddd;
}
.ContentWrap .materialWrap .material {
    width: 100%;
}
.ContentWrap .materialWrap .material .materialTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}
.ContentWrap .materialWrap .material .materialContent {
    display: flex;
}

.ContentWrap .materialWrap .material .materialContent img {
    width: 100px;
    object-fit: cover;
    margin-right: 12px;
}
.ContentWrap .subSidiaryWrap {
    width: 100%;
    border-top: 1px solid #ddd;
}
.ContentWrap .subSidiaryWrap .subSidiary {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid #ddd;
    align-items: center;
}
.ContentWrap .subSidiaryWrap .subSidiary p {
    font-size: 14px;
    line-height: 20px;
}
.ContentWrap .subSidiaryWrap .subSidiary h2 {
    font-size: 14px;
    font-weight: 700;
}
.ContentWrap .warning {
    margin-top: 12px;
    font-size: 14px;
    color: var(--Line, #b80000);
}
.ContentWrap .customerContact {
    flex-direction: column;
    margin-bottom: 12px;
}
.ContentWrap .customerContact p {
    font-size: 16px;
    color: #666;
    margin-bottom: 8px;
}
.ContentWrap .customerContact h1 {
    font-size: 32px;
    color: #07090c;
}
.contentPic {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 4px;
}
.contentPic .imgWrap {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 너비와 같은 비율로 패딩을 설정 */
    overflow: hidden;
}
.contentPic .imgWrap .uploadImg {
    position: absolute; /* imgWrap 기준으로 위치 설정 */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.contentPic .imgWrap .del {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}
.TitleWrap {
    padding-left: 20px;
    padding-top: 20px;
    font-size: 20px;
}
.TitleWrap h1 {
    font-size: 20px;
    font-weight: 700;
}

.grayWrap {
    font-size: 16px;
    padding: 20px;
    background-color: #f1f1f1;
}
.contentTable {
    width: 100%;
}
.contentTable table {
    width: 100%;
    margin-top: 12px;
    table-layout: "fixed";
    border-collapse: separate;
    border-spacing: 0;
}

.contentTable table th {
    text-align: center;
    padding: 8px;
    font-size: 14px;
    background-color: #f1f1f1;
    min-width: 75px;
    border-bottom: 1px solid #ddd;
}
.contentTable table td {
    text-align: center;
    padding: 8px;
    font-size: 14px;
    background-color: white;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}
.SubmitBtnWrap {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #07090c;
    align-items: center;
}
.SubmitBtnWrap p {
    color: white;
    font-size: 20px;
    font-weight: 700;
}

.contentWrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.contentInner {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.loginHeader {
    min-height: 263.5px;
    background-color: #00c0a3;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.loginHeader h2 {
    font-size: 20px;
    font-weight: 700;
    color: white;
    line-height: 27px;
}
.loginHeader .DobaeImg {
    position: absolute;
    top: 95.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
}

.rightLocation {
    margin-left: auto;
    display: block;
}
.textareaWrap textarea {
    padding: 20px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    height: 120px;
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    background: #fff;
    resize: none;
}
.ContentUlWrap {
    gap: 10px;
}
.ContentUlWrap .Ul {
    display: flex;
    align-items: center;
    gap: 10px;
}
.ContentUlWrap .Ul h2 {
    color: #393939;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
}
.ContentUlWrap .Ul p {
    color: #393939;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.searchInput {
    border-radius: 7px;
    border: 1px solid #dcdce4;
    background: #f9f9f9;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.searchInput input {
    border: none;
    background: none;
    width: 100%;
    font-size: 13px;
    color: #32324d80;
}

.searchInput input::placeholder {
    color: #32324d80;
}
.Width100 {
    width: 100%;
}
.custom-toast-error-login {
    border-radius: 10px;
    width: 86%;
    margin: 2% 7%;
    background-color: #43465d1a !important;
    color: #393939 !important;
    border: none !important;
    font-size: 14px;
}
.custom-toast-error-login.materialSelect {
    border-radius: 10px;
    width: 90%;
    margin: 0% 5% 20% 5%;
    background-color: #f2f2f2 !important;
    color: #393939 !important;
    border: none !important;
    font-size: 14px;
}
.custom-toast-error-login.addressIn {
    width: 90%;
    margin: 0% 5% 33% 5%;
    font-size: 14px;
    background-color: #ffffff !important;
    border: 1px solid #43465d99 !important;
}
.custom-toast-error-login svg {
    fill: #393939 !important;
}
.custom-toast-error {
    border-radius: 10px;
    font-size: 14px;
    width: 86%;
    margin: 2% 7%;
    background-color: #f4f6f7 !important;
    color: #007965 !important;
    border: 1px solid #009982 !important;
}
.custom-toast-success {
    border-radius: 10px;
    font-size: 14px;
    width: 98%;
    margin: 1%;
    background-color: #f4f6f7 !important;
    color: #007965 !important;
    border: 1px solid #009982 !important;
}

.custom-toast-error svg {
    fill: #007965 !important;
}
/* .Toastify__toast {
    border-radius: 10px;
}
.Toastify__toast-container {
    width: 98vw;
    margin: 1vw 1vw;
    border-radius: 10px;
} */
.PaginationContent {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
}
.PaginationContent .pagination {
    display: flex;
}
.pagination {
    display: flex;
    justify-content: center;
}
.pagination ul {
    list-style: none;
    padding: 0;
}
ul.pagination li {
    display: inline-block;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
}
ul.pagination li:first-child {
    border-radius: 5px 0 0 5px;
}
ul.pagination li:last-child {
    border-radius: 0 5px 5px 0;
}
ul.pagination li a {
    text-decoration: none;
    color: #32324d;
    background-color: white;
    font-size: 13px;
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
    border-radius: 5px;
}
ul.pagination li.active a {
    color: white;

    background: #32324d;

    font-weight: 700;
}
ul.pagination li.active {
}
ul.pagination li a:hover,
ul.pagination li a.active {
    color: white;
    font-weight: 700;
}

/* ul.pagination li [aria-label="Go to first page"] {
    content: url("../images/pagination_first.svg");
} */

ul.pagination li [aria-label="Go to previous page"] {
    width: 50px;
    color: #32324d80;
    background-color: white;
    border: 1px solid #32324d1a;
    margin-right: 10px;
}

ul.pagination li [aria-label="Go to next page"] {
    width: 50px;
    color: white;
    background-color: #32324d;

    margin-left: 10px;
}
.materialDetailEditor {
    font-size: 12px;
    line-height: 15px;
}
.materialDetailEditor img {
    max-width: 100%;
}
/* ul.pagination li [aria-label="Go to last page"] {
    content: url("../images/pagination_last.svg");
} */

.warningWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}
.warningWrapNoCeiling {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
}
.warningOrange {
    background: rgba(218, 135, 59, 0.15);
    padding: 20px;
    display: flex;
    align-items: flex-start;
    border-radius: 7px;
    gap: 10px;
}
.warningOrange .warningText {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.warningOrange .warningText h2 {
    color: #e1701e;
    font-size: 12px;
    line-height: 19px;
    font-weight: 500;
}
.warningOrange .warningText ul {
    color: #e1701ecc;
    font-size: 12px;
    line-height: 19px;
    padding-left: 15px;
}
.warningOrange .warningText li {
    list-style: disc;
}

.warningGreen {
    padding: 20px;
    display: flex;
    align-items: flex-start;
    border-radius: 7px;
    gap: 10px;
    background: #00c0a3;
}
.warningGreen .warningText {
    gap: 10px;
}
.warningGreen .warningText h2 {
    font-size: 12px;
    line-height: 19px;
    font-weight: 700;
    margin-bottom: 5px;
    color: white;
}
.warningGreen .warningText p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    color: #ffffffcc;
}
.cautionWrap {
    background-color: #e069691a;
    padding: 20px;
    color: #ac3232;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.cautionWrap p {
    color: #ac3232;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
}
.cautionWrap ul {
    color: #ac3232;
    font-size: 12px;

    font-weight: 400;
    line-height: 19px;
}

.errorMsg {
    background: #43465d1a;
    padding: 20px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.errorMsg .content {
    display: flex;
    gap: 10px;
    align-items: center;
}
.errorMsg .content img {
    width: 18px;
    height: 18px;
}
.errorMsg .content p {
    padding-top: 3px;
    font-size: 12px;
}
.errorMsg .arrowRight {
    width: 7px;
}
.noImgDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 14px;
    text-align: center;
    line-height: 18px;
}
