.FlexCenter {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.PaddingBottom {
    padding-bottom: 20px;
}
.PaddingTop {
    padding-top: 20px;
}
.PaddingTopBottom {
    padding: 20px 0px;
}
.PaddingAll {
    padding: 20px;
}
.Ctitle {
    font-size: 20px;
    font-weight: 700;
}
.btn {
    display: flex;
    width: 160px;
    height: 36px;
    padding: 0px 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background-color: var(--Primary, #51baa4);
    color: #fff;
}

.LastBtn {
    width: 100%;
    height: 64px;
    font-size: 20px;
    border-radius: 10px;
}
.CancelBtn {
    background-color: #b9bbc3;
}
.uploadBtn {
    background-color: #fff;
    border: 1px solid #51baa4;
    color: #51baa4;
}
.addBtn {
    width: 80px;
    font-size: 14px;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    padding: 20px;
}
.overlay2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 40;
    padding: 20px;
}
.popup {
    background: white;

    border-radius: 12px;
    z-index: 30;
    width: 100%;
    max-width: 650px;
}
.popup .popupTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 20px;
}
.popup .popupTitle h1 {
    color: #393939;

    font-size: 14px;
    font-weight: 700;
}
.popup .popupContent {
    border-top: 1px solid #ddd;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 105px;
}

.popup .popupContent .info {
    display: flex;
    align-items: stretch;
    border-bottom: 1px solid #ddd;
}
.popup .popupContent .info .title {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    background: var(--Grey_F1, #f1f1f1);
    width: 20%;
    padding: 8px;
    line-height: 20px;

    box-sizing: border-box;
}

.popup .popupContent .info .infoContent {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 8px;
    line-height: 20px;
    width: 80%;
}
.popup .popupContent .info .infoContent ul li {
    list-style-type: none;
    padding-left: 1em; /* Adjust as needed */
    text-indent: -1em; /* Adjust as needed */
}
.popup .popupContent .info .infoContent ul li::before {
    content: "-";
    margin-right: 10px;
}
.LongPopup {
    border-radius: 20px 20px 0px 0px;
    background: #fff;
    z-index: 30;
    width: 100%;
    max-width: 700px;
    /* max-height: 500px; */
    min-height: 100vh;
    position: fixed;
    /* top: 70px; */
    /* bottom: 0; */
}

/* 스크롤바 숨기기 */

.LongPopup .popupTitle {
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px;
    border-bottom: 1px solid #ddd;
}
.LongPopup .popupTitle h1 {
    color: #393939;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}
.LongPopup .materialPopupContentWrap .materialPopupContent {
    padding: 20px;
}
.LongPopup
    .materialPopupContentWrap
    .materialPopupContent
    .materialPopupSearch
    .searchWrap {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
}
.LongPopup
    .materialPopupContentWrap
    .materialPopupContent
    .materialPopupSearch
    .searchWrap
    select {
    width: 100%;
    border-radius: 7px;
    border: 1px solid #dcdce4;
    background: #f9f9f9;
    padding: 10px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #32324d80;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("../images/selectDown.svg") no-repeat center right 14px;
}
.LongPopup
    .materialPopupContentWrap
    .materialPopupContent
    .materialPopupSearch
    .Count {
    color: #514e59;
    text-align: end;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin: 10px 0;
}
.LongPopup .materialPopupContentWrap .materialList_wrap {
    height: calc(100vh - 350px);
    overflow-y: auto;
}
.LongPopup .materialPopupContentWrap .materialList_wrap .materialList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    padding-bottom: 150px;
}
.LongPopup .materialPopupContentWrap .sub_materialList_wrap {
    height: calc(100vh - 300px);
    overflow-y: auto;
}
.LongPopup .materialPopupContentWrap .sub_materialList_wrap .sub_materialList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    padding-bottom: 50px;
}
.materialPopupContentWrap2 {
    /* max-height: calc(100vh - 125px); */
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.materialPopupContentWrap2 .goBack {
    background-color: white;
    border: 2px solid #249987;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;

    right: 20px;
    cursor: pointer;
    bottom: 25%;
}

.materialRecommandWrap {
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.materialRecommandWrap .title {
    font-size: 13px;
    font-weight: 700;
    color: #393939;
}
.materialRecommandWrap .materialRecommand {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4px;
}
.materialRecommandWrap .materialRecommand .recommandThing {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #32324d;
}
.materialRecommandWrap .materialRecommand .recommandThing h1 {
    font-weight: 600;
    font-size: 13px;
}
.materialRecommandWrap .materialRecommand .recommandThing p {
    font-weight: 400;
    font-size: 12px;
}
.materialRecommandWrap .materialRecommand .recommandThing .ImgWrap {
    position: relative;
    width: 100%;
    aspect-ratio: 1;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    overflow: hidden;
}
.materialRecommandWrap .materialRecommand .recommandThing .ImgWrap img {
    width: 100%;
    height: 100%;
}
.recommandThing .ImgWrap .badge {
    border-radius: 3px;
    padding: 2px 5px;
    min-width: 20%;
    font-size: 10px;
    position: absolute;
    top: 5px;
    left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.recommandThing .ImgWrap .badge.BEST {
    background-color: #00c0a3;
    color: white;
}
.recommandThing .ImgWrap .badge.NEW {
    background-color: white;
    color: #000000;
    border: 1px solid #000000;
}
.recommandThing .ImgWrap .badge.PREMIUM {
    background: #1a3a5f;
    color: white;
}
.recommandThing .ImgWrap .badge.HIGH-END {
    background: #393939;
    color: white;
}
.recommandThing .ImgWrap .badge.None {
    display: none;
}
.Header {
    width: 100%;
    background-color: #f0ce12;
    padding: 15.5px 45px 16.5px 45px;
    font-size: 20px;
    font-weight: 700;
    color: #07090c;
}
.InputWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}
.InputWrap input {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: flex-start;
    width: 100%;
}
.InputWrap input:read-only {
    background-color: #ddd;
}
.SelectWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
}
.SelectWrap select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #ddd;
    background: url("../images/down_black.svg") no-repeat center right 24px;
    background-position: calc(100% - 5px) center;
    background-size: 20px;
    font-size: 14px;
    padding: 12px 50px 12px 12px;
    height: 100%;
    color: #333333;
    flex: 1;
    border-radius: 5px;
}
.TextAreaWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.TextAreaWrap textarea {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 120px;
    gap: 12px;

    resize: none;
}
.TextAreaWrap textarea::placeholder {
    font-size: 14px;
    color: #ccc;
}

.TextAreaWrap textarea:read-only {
    background-color: #ddd;
}
.HeaderWrapper {
    padding: 20px;
    flex-direction: column;
    gap: 20px;
}
.HeaderWrapper .InnerHeader {
    flex-direction: column;

    gap: 8px;
}
.HeaderWrapper .InnerHeader h1 {
    font-size: 24px;
    font-weight: 700;
    color: #07090c;
}

.HeaderWrapper .InnerHeader p {
    font-size: 14px;
    color: #666;
}
.HeaderWrapper .InnerHeader ul {
    font-size: 14px;
    color: #666;
    line-height: 20px;
    padding-left: 20px;
}
.HeaderWrapper .InnerHeader li {
    list-style: disc;
    line-height: 20px;
}
.HeaderWrapper .InnerHeaderSummary {
    width: 100%;
    border-radius: 12px;
    background: #c8f0e6;
    padding: 20px;
    flex-direction: column;
    gap: 4px;
}
.HeaderWrapper .InnerHeaderSummary p {
    color: #51baa4;
    font-size: 14px;
    font-weight: 700;
}
.HeaderWrapper .InnerHeaderSummary h1 {
    color: #0f7d66;
    font-size: 24px;
    font-weight: 700;
}
.InnerHeaderOl {
    background-color: #f1f1f1;
    border-radius: 8px;
    width: 100%;
    padding: 12px;
}
.InnerHeaderOl ol {
    display: flex;
    gap: 4px;
    flex-direction: column;
    list-style-type: decimal;
    padding: 10px 20px;
    line-height: 20px;
}
.InnerHeaderOl ol li {
    list-style: auto;
}
.ContentCheckbox {
    padding: 0 20px;
    margin-bottom: 32px;
}

.checkbox {
    display: none;
}
.checkbox + label {
    display: inline-flex;
    align-items: center;
    padding-left: 28px;
    line-height: 20px;
    color: var(--Dark, #333);
    font-size: 14px;
    font-weight: 400;
    background: url("../images/checkbox_off.svg") no-repeat center left;
    background-size: 20px;
    cursor: pointer;
    transition: all 0.3s;
}
.checkbox:checked + label {
    background: url("../images/checkbox_on.svg") no-repeat center left;
    background-size: 20px;
}

.checkbox2 {
    display: none;
}
.checkbox2 + label {
    display: inline-flex;
    align-items: center;
    padding-left: 20px;
    padding-top: 2px;
    /* line-height: 20px; */
    color: var(--Dark, #333);
    font-size: 12px;
    font-weight: 400;
    background: url("../images/checkbox_off2.svg") no-repeat center left;
    background-size: 12px;
    cursor: pointer;
    transition: all 0.3s;
}
.checkbox2:checked + label {
    background: url("../images/checkbox_on2.svg") no-repeat center left;
    background-size: 12px;
}
.checkbox2.finish:checked + label {
    background: url("../images/checkbox_on3.svg") no-repeat center left;
    background-size: 12px;
}
.ContentNextBtn {
    padding: 0px 20px 20px 20px;
}

.ContentNextBtn button {
    padding: 20px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    background-color: #51baa4;
    width: 100%;
    border-radius: 4px;
}
.AccordionWrap {
    border-top: 1px solid #eee;
    padding-bottom: 32px;
}
.accordion-section {
    width: 100%;
}
.accordion-section .accordion-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    cursor: pointer;
}
.accordion-title.active {
    font-weight: 700;
}
.accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    border-bottom: 1px solid #eee;
}

.accordion-content.active {
    padding: 20px;
    background-color: #f2f4f7;
    max-height: 400px;
    overflow-y: auto;
}
.InnerWrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.InnerContentWrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.ContentUl {
    padding: 20px;
}
.ContentUl ul {
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    line-height: 20px;
}

.ContentUl ul li {
    list-style: disc;
}

.headerWrap {
    width: 100%;
    line-height: 20px;
    font-size: 14px;
}
.headerWrap h2 {
    font-weight: 400;
}
.headerWrap h2 span {
    font-weight: 700;
}
.ClkMaterialWrap {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 16px;
}
.ClkMaterialWrap .materialClk {
    width: 50%;
    padding: 20px;
    color: #ccc;
    border-bottom: 4px solid#ccc;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.ClkMaterialWrap .materialClk.active {
    color: #51baa4;
    border-bottom: 4px solid#51baa4;
}

.wallPaperInquiry {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}
.wallPaperInquiry .mainTitle {
    font-size: 16px;
    font-weight: 700;
}
.wallPaperInquiry .wallPaperInquiryWrap h1 {
    font-size: 14px;

    font-weight: 700;
    margin-bottom: 12px;
}
.wallPaperInquiry .wallPaperInquiryWrap .wallPaperInquiryContentWrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    font-size: 14px;
}
.wallPaperInquiry
    .wallPaperInquiryWrap
    .wallPaperInquiryContentWrap
    .wallPaperInquiryContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.wallPaperInquiry
    .wallPaperInquiryWrap
    .wallPaperInquiryContentWrap
    .wallPaperInquiryContent
    img {
    width: 100%;
    height: auto;
}
.wallPaperInquiry
    .wallPaperInquiryWrap
    .wallPaperInquiryContentWrap
    .wallPaperInquiryContent
    button {
    border-radius: 4px;
    background: #51baa4;
    color: #fff;
    padding: 10px;
}
.wallPaperSearchWrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.wallPaperSearchWrap .mainTitle {
    font-size: 20px;
    font-weight: 700;
}
.wallPaperSearchWrap .wallPaperSearch {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.wallPaperListWrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.wallPaperListWrap .mainTitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.wallPaperListWrap .wallPaperList {
    display: flex;
    padding: 12px;
    flex-direction: column;
    gap: 4px;
    border-radius: 8px;
    background: #c8f0e6;
}
.wallPaperListWrap .wallPaperList ul {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-left: 20px;
}
.wallPaperListWrap .wallPaperList ul li {
    list-style: disc;
}
.notification {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.notification .mainTitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.notification .notificationContent {
    display: flex;
    padding: 12px;
    gap: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    background-color: #f1f1f1;
    border-radius: 4px;
}
.InnerHeadeImg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 12px;
}
.InnerHeadeImg .ImgWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 200px;
}
.InnerHeadeImg .ImgWrap img {
    width: 100%;
    height: auto;
    object-fit: cover;
}
.ulHeaderWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.ulHeaderWrap h1 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #07090c;
}
.ulHeaderWrap ul {
    padding-left: 20px;
    list-style: disc;
    line-height: 20px;
}
.ulHeaderWrap ul li {
    list-style: disc;
}
.UlContentWrap {
    background-color: #f1f1f1;
    display: flex;
    padding: 12px;
    flex-direction: column;
    gap: 4px;
}
.UlContentWrap ul {
    background-color: #f1f1f1;
    display: flex;
    padding-left: 20px;
    flex-direction: column;
    line-height: 20px;
    gap: 4px;
}
.UlContentWrap ul li {
    list-style: disc;
}
.OLContentWrap {
    display: flex;

    flex-direction: column;
    gap: 4px;
}
.OLContentWrap ol {
    display: flex;
    padding-left: 20px;
    flex-direction: column;
    line-height: 20px;
    gap: 4px;
}
.OLContentWrap ol li {
    list-style: decimal;
}
.BtnWrapperThree {
    display: flex;

    gap: 8px;
}
.BtnWrapperThree button {
    color: white;
    border-radius: 4px;
    background-color: #ccc;
    padding: 10px;
}
.BtnWrapperThree .active {
    background-color: #51baa4;
}
.ImgGridWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4px;
}
.submitHistory {
    width: 100%;
}
.submitHistory div {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.submitHistory .title {
    background-color: #ddd;
    color: #666;
}
.submitHistory .historyContent {
    border-bottom: 1px solid #ddd;
}
.submitHistory .historyContent:first-child {
    border-top: 1px solid #ddd;
}
.accordion-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.accordion-text p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
.accordion-text ul {
    padding-left: 20px;
    list-style: disc;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
}
.accordion-text li {
    list-style: disc;
    line-height: 20px;
}
.accordion-text ol {
    list-style: disc;
    line-height: 20px;
}
.materialWrap {
    padding: 20px 0;
    display: flex;
    width: 100%;
    border-bottom: 1px solid #ddd;
}
.materialWrap .material {
    width: 100%;
}
.materialWrap .material .materialTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}
.materialWrap .material .materialContent {
    display: flex;
}

.materialWrap .material .materialContent img {
    width: 100px;
    object-fit: cover;
    margin-right: 12px;
}
.materialWrap .material .materialContent textarea {
    width: 100%;
    height: auto;
    resize: none;
    border: 1px solid #ddd;
}
.materialBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #51baa4;
    color: #fff;
    padding: 5px;
    font-size: 12px;
    width: 20%;
    height: 100%;
    cursor: pointer;
}

.ProgressBar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: relative;
    z-index: 10;
}
.ProgressBar .progressWrap {
    width: 100%;
    gap: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.ProgressBar .progressBarLine {
    width: 15%;
    height: 4px;
    background-color: #f1f1f1;
    border-radius: 4px;
    position: absolute;
    z-index: -1;
    top: 22px;
}
.ProgressBar .progressWrap p {
    font-size: 10px;
}
.ProgressBar .progressWrap .progress {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    padding-top: 2px;
    font-size: 14px;
    font-weight: 700;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
}
.ProgressBar .progressWrap .progressText {
    transition: color 0.3s, font-weight 0.3s;
}
.ProgressBar .progressWrap .progress.isGreenActive {
    background-color: white;
    color: #00c0a3;
}
.ProgressBar .progressWrap .progressText.isGreenActive {
    color: #fff;
    font-weight: 700;
}
.ProgressBar .progressWrap .progress.isGreenNonFinish {
    border: 1px solid rgba(0, 192, 163, 0.14);
    background: #4fc9b7;
    color: rgba(255, 255, 255, 0.5);
}
.ProgressBar .progressWrap .progressText.isGreenNonFinish {
    color: #ffffff99;
}
.ProgressBar .progressWrap .progress.isGreenFinish {
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: #85dacd;
    color: white;
}
.ProgressBar .progressWrap .progressText.isGreenFinish {
    color: #ffffffb3;
}
.ProgressBar .progressWrap .progress.active {
    border: 1px solid #fff;
    background: #00c0a3;
    color: white;
}
.ProgressBar .progressWrap .progressText.active {
    color: #00c0a3;
    font-weight: 700;
}
.ProgressBar .progressWrap .progress.finish {
    border: 1px solid #fff;
    background: #6ad1c1;
    color: white;
}
.ProgressBar .progressWrap .progressText.finish {
    color: #43af9f;
}
.ProgressBar .progressWrap .progress.nonFinish {
    border: 1px solid rgba(91, 91, 92, 0.14);
    background: #fff;
    color: rgba(46, 46, 46, 0.5);
}
.ProgressBar .progressWrap .progressText.nonFinish {
    color: #84848499;
}
.progressBarLine.greenActive {
    background: rgba(255, 255, 255, 0.13);
    transition: background 0.3s;
}
.progressBarLine.nonFinish {
    background: #e5e5e8;
    transition: background 0.3s;
}
.progressBarLine.finish {
    background: #00c0a3;
    transition: background 0.3s;
}
.BottomBtnWrap {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #ddd;
    position: fixed;
    bottom: 0;
    left: 50%; /* left를 50%로 설정 */
    transform: translateX(-50%); /* 가운데 정렬을 위해 transform 사용 */
    background-color: white;
    width: 100%;
    max-width: 780px;

    /* z-index: 50; */
    /* position: absolute; */
    /* margin-top: 50px; */
}

.BottomBtnWrap .bottomBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
}
.BottomBtnWrap .bottomBtn img {
    width: 18px;
}
.BottomBtnWrap .bottomBtn p {
    font-size: 10px;
}
.BottomBtnWrap .bottomBtn p.active {
    font-size: 10px;
    font-weight: 700;
    color: #00c0a3;
}
.loginMainWrap {
    z-index: 5;
    position: relative;
    background-color: #ffffff;
    padding: 20px;
    margin-top: 110.5px;
    /* height: 65vh; */
}
.loginMainWrap .loginMain p {
    font-size: 14px;
}
.LoginError {
    padding: 20px;
    background: #43465d1a;
    display: flex;
    border-radius: 7px;
    width: calc(100% - 40px);
    position: fixed;
    bottom: 20px;
    margin: 0px 20px;
}
.LoginError .warningImg {
    padding-right: 15px;
}
.LoginError p {
    font-size: 12px;
    line-height: 19px;
}
.loginMainWrap .loginMain {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
}
.loginMainWrap .loginMain .loginInput {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}
.inputBox {
    padding: 15px;
    border: 1px solid #ddd;
    background-color: white;
    font-size: 14px;
    width: 100%;
    border-radius: 7px;
    text-align: center;
}

.inputBox::placeholder {
    color: #ddd;
}
.inputBox:focus::placeholder {
    color: transparent;
}
.btnBox {
    background-color: #00c0a3;
    font-size: 14px;
    width: 100%;
    border-radius: 7px;
    text-align: center;
    cursor: pointer;
    font-weight: 700;
    color: white;
    line-height: 19px;
    padding: 15px;
}
.btnBox.white {
    border: 1px solid #393939;
    color: black;
    background-color: white;
}
.btnBox.active {
    background-color: #43465d1a;
    color: #43465d4d;
}
.EstimateHeader {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: #00c0a3;
    min-height: 366px;
    color: #07090c;
}
.EstimateHeader h2 {
    margin-top: 40px;
    margin-bottom: 25px;
    font-size: 17px;
    font-weight: 700;
    color: white;
}
.EstimateHeader .EstimateHeaderMainWrap {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.EstimateHeader .EstimateHeaderMainWrap .EstimateHeaderMain {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.EstimateHeader .EstimateHeaderMainWrap .EstimateHeaderMain p {
    margin-top: 18px;
    font-size: 14px;
    font-weight: 400;
    color: #dadded;
}
.EstimateHeader .EstimateHeaderMainWrap .EstimateHeaderMain p span {
    margin-top: 18px;
    font-size: 14px;
    font-weight: 700;
    color: white;
}
.EstimateHeader .EstimateHeaderMainWrap .estimatePrice {
    margin-top: 18px;
    font-size: 14px;
    font-weight: 400;
    color: white;
}
.EstimateHeader .EstimateHeaderMainWrap .estimatePrice span {
    font-size: 24px;
    font-weight: 700;
    color: white;
}
.EstimateBody {
    padding-bottom: 10px;
}
.EstimateBody .EstimateBodyMain {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
}
.ClkContentWrap {
    display: flex;
    gap: 20px;
    border-bottom: 1px solid #ddd;
    padding: 0 15px;
}
.ClkContentWrap .ClkContent {
    padding: 15px 0;
    color: #adadad;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.ClkContentWrap .ClkContent.active {
    color: #393939;
    padding-bottom: 12px;
    border-bottom: 3px solid #393939;
}

.contentBox {
    display: flex;
    align-items: center;
}
.contentBox .contentBoxHeader {
    color: #393939;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    min-width: 20%;
    word-break: keep-all;
}
.contentBox .contentBoxContent {
    color: #393939;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 10px;

    word-break: break-all;
}
.cutLine {
    height: 6px;
    background-color: #5f5f5f0d;
}
.EstimateBodyMain ul {
    color: #393939;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    padding-left: 20px;
}
.EstimateBodyMain ul li {
    list-style: disc;
}
.EstimateBodyMain ul li span {
    font-weight: 700;
}
.EstimateBodyMain .BtnWrapper {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.ContentBody {
    padding: 20px;
}
.ContentBody .ContentBodyHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    /* margin: 20px 0px 15px 0; */
}
.ContentBody .ContentBodyHeader h1 {
    color: #393939;
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.ContentBody .ContentBodyHeader p {
    color: #393939;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.ContentBody .ContentBodyText {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 23px;
}
.ContentBody .ContentBodyText p {
    color: #393939;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    line-height: 23px;
}
.ContentBody .ContentBodyText p span {
    font-weight: 700;
}
.ContentBody .ContentBodyUl {
    padding: 15px;
    background-color: #9a9a9a1a;
    border-radius: 10px;
}
.ContentBody .ContentBodyUl ul {
    color: #393939;
    padding-left: 15px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 5px;
    line-height: 17px;
}
.ContentBody .ContentBodyUl ul li {
    list-style: disc;
}
.ContentBody h3 {
    color: #393939;

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 18px;
}
.pSpan {
    width: 100%;
    text-align: center;
    padding-bottom: 32px;
}
.pSpan p {
    color: #393939;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.pSpan p span {
    font-weight: 700;
}
.contractBoxWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.contractBox {
    display: flex;
    width: 100%;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    position: relative;
    background-color: #f9f9f9;
    color: #32324db3;
    cursor: pointer;
}
.contractBox.active {
    background-color: #00c0a3;
    color: white;
}
.AgreeDateWrap {
    /* margin-top: 88px; */
    background-color: #666666;
    padding: 15px 30px;
    display: flex;
    border-radius: 10px;
    position: fixed;
    bottom: 20px;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    max-width: 650px;
}
.AgreeDateWrap.nonFixed {
    position: relative;
    width: 90%;
    bottom: 0px;
}
.AgreeDateWrap.active {
    /* margin-top: 88px; */
    background-color: #00c0a3;
}
.AgreeDateWrap .Chk {
    width: 21px;
    margin-right: 20px;
}
.AgreeDateWrap .AgreeDate {
    display: flex;
    flex-direction: column;
    line-height: 19px;
}
.AgreeDateWrap .AgreeDate p {
    color: #fff;

    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
}
.AgreeDateWrap .AgreeDate h4 {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
}
.InnerContentWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0px;
}
.ContentBodyLineWrap {
    border-radius: 10px;
    border: 1px solid #39393933;
    padding: 10px 15px 15px 15px;
}
.ContentBodyLineWrap.Price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}
.ContentBodyLineWrap h1 {
    color: #393939;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
}
.ContentBodyLineWrap h1 span {
    color: #393939;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    margin-left: 5px;
}
.ContentBodyLineWrap .ContentBodyLine {
    display: flex;
    gap: 10px;
    border-bottom: 1px solid #39393933;
    width: 100%;
    padding: 15px 0px;
}
.ContentBodyLineWrap .ContentBodyLine h2 {
    color: #393939;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    padding-left: 10px;
    width: 20%;
}
.ContentBodyLineWrap .ContentBodyLine input {
    width: 70%;
    font-size: 12px;
    border: none;
    color: #00c0a3;
    font-weight: 700;
}
.ContentBodyLineWrap .ContentBodyLine input:read-only {
    color: #393939;
    font-weight: 400;
}
.ContentBodyLineWrap .ContentBodyLine p {
    color: #393939;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
}
.ContentBodyLine .editBtn {
    border-radius: 3px;
    border: 1px solid #393939;
    background: #393939;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 3px;
    cursor: pointer;
    width: 40px;
}
.ContentBodyLine .editBtn p {
    color: white;
}
.ContentBody .ContentBodyUl2 ul {
    color: #393939;

    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    padding-left: 15px;
    padding-bottom: 35px;
}
.ContentBody .ContentBodyUl2 ul li {
    list-style: disc;
}
.ContentBody .timerWrap {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
}
.ContentBody .timerWrap .timer {
    display: flex;
    align-items: center;
}
.alignLine {
    height: 80%;
    width: 1px;
    background-color: #39393933;
    margin: 0px 10px 1px 10px;
}
.ContentBody .timerWrap .timer h1 {
    color: #393939;

    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
}
.ContentBody .timerWrap .timer p {
    color: #393939;

    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
}
.ContentHeader {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #ececef80;
}

.ContentHeader p {
    color: #393939;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
}
.ContentBody .ContentBodyText2 {
    color: #393939;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
}
.materialBox {
    display: flex;
    margin-top: 15px;
}
.materialBox .imgWrap {
    min-width: 105px;
    width: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    position: relative;
}

.materialBox .imgWrap .badge {
    border-radius: 3px;
    padding: 5px;
    min-width: 20%;
    font-size: 10px;
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.materialBox .imgWrap .badge.BEST {
    background-color: #00c0a3;
    color: white;
}
.materialBox .imgWrap .badge.NEW {
    background-color: white;
    color: #000000;
    border: 1px solid #000000;
}
.materialBox .imgWrap .badge.PREMIUM {
    background: #1a3a5f;
    color: white;
}
.materialBox .imgWrap .badge.HIGH-END {
    background: #393939;
    color: white;
}
.materialBox .imgWrap .badge.None {
    display: none;
}
.materialBox .imgWrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.materialBox .materialBoxInfo {
    position: relative;
    width: 80%;
    display: flex;
    flex-direction: column;
    padding: 10px 0px 0px 20px;
    gap: 10px;
}
.materialBox .materialBoxInfo .materialBoxTitle {
    /* width: 80%; */
    display: flex;

    color: #32324d;

    font-size: 12px;
    font-style: normal;
    line-height: normal;
    align-items: center;
}
.materialBox .materialBoxInfo .materialBoxTitle p {
    font-weight: 400;
    font-size: 12px;

    /* margin-top: -2px; */
}
.materialBox .materialBoxInfo .materialBoxTitle h1 {
    font-weight: 700;
    font-size: 12px;
}
.materialBox .materialBoxInfo .materialBoxContent {
    color: #32324d;

    font-size: 11px;
}
.materialBox .materialBoxInfo .materialBoxInputWrap {
    display: flex;
    padding: 10px;
    align-items: center;
    border-radius: 7px;
    border: 1px solid #ececef;
    background-color: #f5f5f7e5;
    width: 100%;
    margin-top: 10px;
    height: 35px;
}
.materialBox .materialBoxInfo .materialBoxInputWrap.active {
    display: flex;
    padding: 10px;
    align-items: center;
    border-radius: 7px;
    border: 1px solid #fa3e3e;

    background-color: #ffaeae24;
    width: 100%;
    margin-top: 10px;
}

.materialBox .materialBoxInfo .materialBoxInputWrap p {
    color: #393939;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: 19px;
    min-width: 20px;
    word-break: keep-all;
}
.materialBox .materialBoxInfo .materialBoxInputWrap .alignLine {
    margin: 0px 10px;
}
.materialBox .materialBoxInfo .materialBoxInputWrap input {
    border: none;
    background-color: transparent;
    color: #393939;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    width: 100%;
}
.materialBox .materialCancel {
    position: absolute;
    top: 10px;
    right: 0;

    cursor: pointer;
}
.addMaterial {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ececef;
    background: #f2f2f2;
    cursor: pointer;
}
.addMaterial p {
    width: 21px;
    height: 19px;
    flex-shrink: 0;
    color: #b9b9bd;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
}
.firstAdd {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    width: 103px;
    height: 103px;
    padding: 30px;
    border-radius: 10px;
    border: 1px solid #ececef;
    background: #f2f2f2;
    cursor: pointer;
}
.firstAdd img {
    width: 42px;
}
.firstAdd p {
    color: #b9b9bd;

    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
}
.secondAdd {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid #ececef;
    background: #f2f2f2;
    height: 40px;
}
.secondAdd p {
    color: #b9b9bd;

    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
}
.sigongDate {
    padding: 15px;
    border-radius: 7px;
    background: #393939;
    color: white;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
}
.MaterialFinalLookWrap {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}
.MaterialFinalLookWrap .imgWrap {
    width: 62px;
    height: 62px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    overflow: hidden;
    position: relative;
}
.MaterialFinalLookWrap .imgWrap .badge {
    border-radius: 3px;
    padding: 2.5px;
    min-width: 20%;
    font-size: 8px;
    position: absolute;
    top: 5px;
    left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.MaterialFinalLookWrap .imgWrap .badge.BEST {
    background-color: #00c0a3;
    color: white;
}
.MaterialFinalLookWrap .imgWrap .badge.NEW {
    background-color: white;
    color: #000000;
    border: 1px solid #000000;
}
.MaterialFinalLookWrap .imgWrap .badge.PREMIUM {
    background: #1a3a5f;
    color: white;
}
.MaterialFinalLookWrap .imgWrap .badge.HIGH-END {
    background: #393939;
    color: white;
}
.MaterialFinalLookWrap .imgWrap .badge.None {
    display: none;
}

.MaterialFinalLookWrap .imgWrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.MaterialFinalLookWrap .materialBoxInfo {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.MaterialFinalLookWrap .materialBoxInfo .materialBoxTitle {
    display: flex;
    align-items: center;
}
.MaterialFinalLookWrap .materialBoxInfo .materialBoxTitle p {
    color: #32324d;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.MaterialFinalLookWrap .materialBoxInfo .materialBoxTitle h1 {
    color: #32324d;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.MaterialFinalLookWrap .materialBoxInfo .materialBoxContent {
    color: #32324d;

    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.finishedHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #00c0a3;
    color: white;
    padding-bottom: 30px;
    padding-top: 15px;
}
.finishedHeader .balancePayYesWrap {
    margin-top: 30px;
    text-align: center;
}
.finishedHeader .balancePayYesWrap p {
    font-size: 14px;
    line-height: 19px;
}
.finishedHeader .balancePayYesWrap span {
    font-weight: 700;
}
.finishedHeader .balancePayWrap {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}

.finishedHeader .balancePayWrap p {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.finishedHeader .balancePayWrap p span {
    font-weight: 700;
}
.finishedHeader .balancePayWrap h2 {
    color: #fff;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.finishedHeader .balancePayWrap h2 span {
    font-size: 14px;
    font-weight: 400;
}
.finishedHeader .accountment {
    border-radius: 8px;
    background-color: #ffffff33;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    width: 80%;
    margin-top: 15px;
}
.finishedHeader .accountment p {
    padding-top: 3px;
}
.finishedHeader .accountment .imgWrap {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cationWrap {
    background: #e069691a;
    padding: 15px 20px;
    border-radius: 10px;
}
.cationWrap h2 {
    color: #ac3232;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
}
.cationWrap p {
    color: #ac3232;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
}
.cationWrap ul {
    color: #ac3232;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
}
.CircleUlWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.CircleUl {
    display: flex;
    align-items: center;
    gap: 10px;
}
.CircleUl p {
    color: #393939;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
}
.CircleUl .Circle {
    background-color: black;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    font-size: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ClkCheckWrap {
    display: flex;

    flex-direction: column;
    gap: 10px;
}

.ClkCheckWrap .ClkCheck {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #e7e7e7;
    color: #32324db2;
    font-size: 14px;
}
.ClkCheckWrap .ClkCheck.active {
    border: 1px solid #00c0a3;
    color: #00c0a3;
}
.ClkCheckWrap .ClkCheck .imgWrap {
    width: 17px;
    height: 17px;
}
.ClkCheckWrap .ClkCheck .imgWrap img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.MaterialPopLookWrap .imgWrap {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 10px;
    border: 1px solid #00000014;
    position: relative;
    overflow: hidden;
}
.MaterialPopLookWrap .imgWrap img {
    width: 100%;
    height: 100%;
}
.MaterialPopLookWrap .imgWrap .badge {
    border-radius: 3px;
    padding: 5px;
    min-width: 20%;
    font-size: 10px;
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.MaterialPopLookWrap .imgWrap .badge.BEST {
    background-color: #00c0a3;
    color: white;
}
.MaterialPopLookWrap .imgWrap .badge.NEW {
    background-color: white;
    color: #000000;
    border: 1px solid #000000;
}
.MaterialPopLookWrap .imgWrap .badge.PREMIUM {
    background: #1a3a5f;
    color: white;
}
.MaterialPopLookWrap .imgWrap .badge.HIGH-END {
    background: #393939;
    color: white;
}
.MaterialPopLookWrap .imgWrap .badge.None {
    display: none;
}

.MaterialPopLookWrap .materialBoxInfo {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.MaterialPopLookWrap .materialBoxInfo .materialBoxTitle {
    display: flex;
    color: #32324d;
}
.MaterialPopLookWrap .materialBoxInfo .materialBoxTitle p {
    font-size: 12px;
}
.MaterialPopLookWrap .materialBoxInfo .materialBoxTitle h1 {
    font-weight: 700;
    font-size: 12px;
}
.MaterialPopLookWrap .materialBoxInfo .materialBoxContent p {
    color: #32324d;

    font-size: 11px;
}
.popTop {
    position: absolute;
    top: 70px;
    left: 0;
    padding: 15px 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 650px;
    background-color: #ffffffb3;
    border-radius: 16px;
    display: flex;
    gap: 10px;
}
.popTop .popTopText p {
    color: #393939;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
}
.popTop .popTopText span {
    font-weight: 700;
    line-height: 19px;
}
.AgreePopContent {
    padding: 20px;
    /* max-height: calc(100vh - 210px); */
    /* height: calc(100vh - 220px); */
    /* height: calc(100% - 65px); */
    overflow-y: auto;
}
.AgreePopContent::-webkit-scrollbar {
    width: 12px;
}

.AgreePopContent::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.AgreePopContent::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, #d3d3d3, #a9a9a9);
    border-radius: 10px;
}

.AgreePopContent::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(45deg, #c0c0c0, #808080);
}

.AgreePopContent .AgreePopContentText {
    color: #393939;
    text-align: center;
    font-size: 12px;
    width: 100%;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 25px;
}
.AgreePopContent .AgreePopContentMain {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.AgreePopContent .AgreePopContentMain .title {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
}
.AgreePopContent .AgreePopContentMain .sigongInfoWrap {
    border-radius: 10px;
    border: 1px solid #39393933;
    display: flex;
    padding: 15px 20px;
    align-items: center;
    justify-content: space-between;
}
.AgreePopContent .AgreePopContentMain .sigongInfoWrap .sigongInfo h2 {
    color: #393939;
    font-size: 12px;
    font-weight: 700;
    line-height: 19px;
}
.AgreePopContent .AgreePopContentMain .sigongInfoWrap .sigongInfo p {
    color: #393939;
    font-size: 10px;
    font-weight: 400;
    line-height: 19px;
}
.AgreePopContent .AgreePopContentMain .sigongInfoWrap h1 {
    color: #393939;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
}
.AgreePopContent .AgreePopContentMain .sigongInfoWrap h1 span {
    color: #393939;
    font-size: 10px;
    font-weight: 400;
    line-height: 19px;
}

.AgreePopContent .agreeTextWrap h3 {
    color: #393939;
    font-size: 12px;
    line-height: 19px;
    font-weight: 700;
}
.AgreePopContent .agreeTextWrap p {
    color: #ff4d00;
    font-size: 12px;
    line-height: 19px;
    padding: 20px 20px 10px 0px;
}
.AgreePopContent .agreeTextWrap ul {
    color: #393939;
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.AgreePopContent .agreeTextWrap ul li {
    list-style: disc;
}
.paginationCustom-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;
}
.paginationCustom-wrap button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5px 8px;
    border-radius: 3px;
    font-size: 14px;
}
.paginationCustom-wrap .paginationCustom-prev {
    margin-right: 15px;
    color: #32324d80;
    background-color: white;
    border: 0.5px solid rgba(50, 50, 77, 0.1);
}
.paginationCustom-wrap .paginationCustom-number.active {
    color: white;
    font-weight: 700;
    font-size: 14px;
    background: #32324d;
}
.paginationCustom-wrap .paginationCustom-next {
    margin-left: 15px;
    color: white;
    background: #32324d;
    border: 0.5px solid rgba(50, 50, 77, 0.1);
}
